export const phoneNoReg = /^[0-9]{11}$/
export const emailReg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const BLOCK_KLASS_LIST = [
  'gitblog',
  'style_guide',
  'pp_package',
  'pp_basic',
  'pp_adv',
  'uxd',
]

export const TOP_BANNER_SHOWROOM_LIST = [
  '/',
  '/catalog',
  '/school',
  '/classes',
  '/event',
  '/enterprise',
  '/onboarding',
  '/startup-station',
  '/content',
]
